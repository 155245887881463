$primary-color: #2F80ED;
$primary-light-color: #EBF3FD;
$secondary-color: #2EC5CE;
$action-btn-color:#FFB800;
$white-color: #ffffff;

$text-black: #0B0D17;
$off-grey: #787878;
$light-gray: rgba(0, 0, 0, 0.3);

$red: #f46a6a;
