@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");
@import 'assets/scss/_variables-new.scss';

body,
html {
  margin: 0;
  padding: 0;
}

.primary-color {
  color: var(--sp-primary-color) !important;
}

.text-black {
  color: var(--sp-text-black) !important;
}

.secondry-color {
  color: var(--sp-action-btn-color) !important;
}

.tertiary-color {
  color: var(--sp-secondary-color) !important;
}

.white-color {
  color: var(--sp-white-color) !important;
}

.gray-color {
  color: var(--sp-light-gray) !important;
}

.off-gray-color {
  color: var(--sp-off-grey);
}

body,
html {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

body {
  background: var(--sp-background-color) !important;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: var(--sp-text-black) !important;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500 !important;
  color: var(--sp-text-black) !important;
}

.text-left {
  text-align: left;
}

.text-center,
.main-sidebar .support {
  text-align: center;
}

.text-right {
  text-align: right;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

.info-text {
  font-size: 0.65rem;
  margin-top: 5px;
}

.warn-text {
  color: var(--sp-danger-text-color);
}

.tooltip-inner {
  background: var(--sp-card-color);
  color: var(--sp-card-text-color);
  font-size: 12px;
  line-height: 16px;
  padding: 10px 10px 15px 10px;
}

::ng-deep .info-tooltip .tooltip-inner {
  background: var(--sp-card-color);
  color: var(--sp-card-text-color);
  box-shadow: 10px 8px 20px 10px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  line-height: 16px;
  padding: 10px 10px 15px 10px;
  z-index: 1;
  top: 150%;
  margin-left: -90px;
}

.tooltip.show {
  opacity: 1;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--sp-secondary-color) !important;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: var(--sp-secondary-color) !important;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--sp-secondary-color) !important;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--sp-secondary-color) !important;
}

.main-sidebar {
  position: fixed;
  z-index: 999;
  background: var(--sp-sidebar-background);
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  color: var(--sp-sidebar-text-color) !important;
  padding: 40px 25px 25px;
  width: 18%;
  height: 100%;
  overflow-y: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main-sidebar::-webkit-scrollbar {
  display: none;
}

.main-sidebar .logo {
  max-width: 165px;
  margin-bottom: 40px;
}

.main-sidebar .logo svg {
  width: 100%;
  color: var(--sp-sidebar-text-color);
}

.main-sidebar nav .navbar-nav {
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main-sidebar nav .navbar-nav li a {
  font-size: 13px;
  font-weight: 700;
  color: var(--sp-sidebar-text-color);
  opacity: 0.5;
}

.main-sidebar nav .navbar-nav li svg {
  color: var(--sp-sidebar-text-color);
}

.main-sidebar nav .navbar-nav li a:hover {
  opacity: 1;
}

.main-sidebar nav .navbar-nav li a .icon {
  display: inline-block;
  width: 38px;

  -webkit-transform: inherit;
  transform: inherit;
}

.main-sidebar nav .navbar-nav .active a {
  opacity: 1;
}

.main-sidebar .support {
  font-size: 11px;
  line-height: 15px;
}

.main-sidebar .support h4 {
  font-size: 12px;
  color: var(--sp-sidebar-text-color) !important;
}

.main-sidebar .support a {
  color: var(--sp-sidebar-text-color);
  border-color: var(--sp-sidebar-text-color);
  margin: 5px 0;
  display: inline-block;
}

.main-sidebar .support .img-box {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  overflow: hidden;
  margin: 5px auto 10px;
}

.main-sidebar .support .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.main-sidebar .hamburger-close {
  margin-bottom: 40px;
  margin-left: 10px;
}

.main-sidebar .hamburger-close span {
  background: var(--sp-white-color) !important;
}

.content-wrapper {
  width: 100%;
}

.top-bar {
  border: var(--sp-topbar-divider-color) 1px solid;
  background: var(--sp-topbar-color);
  border-top: none;
  border-left: none;
  width: 84%;
  padding: 20px 20px 20px 80px;
  position: fixed;
  z-index: 10;
}

.top-bar h1 {
  font-size: 28px;
  color: var(--sp-topbar-text-color) !important;
  font-weight: 400 !important;
  letter-spacing: 1%;
}

.top-bar .profile-photo {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}

.top-bar .profile-photo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.top-bar .btn:hover img {
  -webkit-filter: none;
  filter: none;
}

.menuToggle {
  display: block;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 5px;
}

.menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: var(--sp-primary-color);
  border-radius: 3px;
  z-index: 1;
  -webkit-transform-origin: 4px 0;
  transform-origin: 4px 0;
  -webkit-transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.menuToggle span:first-child {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.menuToggle span:nth-last-child(2) {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.menuToggle a {
  text-decoration: none;
  color: #232323;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.hamburger-close span:nth-last-child(1) {
  opacity: 1;
  -webkit-transform: rotate(-45deg) translate(-3px, -1px);
  transform: rotate(-45deg) translate(-3px, -1px);
}

.hamburger-close span:nth-last-child(2) {
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
  transform: rotate(0deg) scale(0.2, 0.2);
}

.hamburger-close span:nth-last-child(3) {
  -webkit-transform: rotate(45deg) translate(0, -1px);
  transform: rotate(45deg) translate(0, -1px);
}

.menu-open .hamburger-close span:nth-last-child(1) {
  opacity: 1;
  -webkit-transform: rotate(-45deg) translate(-3px, -1px);
  transform: rotate(-45deg) translate(-3px, -1px);
}

.menu-open .hamburger-close span:nth-last-child(2) {
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
  transform: rotate(0deg) scale(0.2, 0.2);
}

.menu-open .hamburger-close span:nth-last-child(3) {
  -webkit-transform: rotate(45deg) translate(0, -1px);
  transform: rotate(45deg) translate(0, -1px);
}

.course-card {
  box-shadow: 10px 8px 20px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: grayscale(0.5) opacity(0.8) !important;
  border-radius: 5px;
  background: var(--sp-card-color);
  margin-bottom: 60px;
}

.course-card .header {
  background: var(--sp-card-header-color);
  border-radius: 5px 5px 0 0;
  color: var(--sp-card-header-text-color);
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  padding: 5px 25px;
}

.course-card .header p {
  font-size: 14px;
  font-weight: 400;
  display: inline;
}

.course-card .body {
  padding: 30px 25px 0;
}

.course-card .body .course-card-text {
  color: var(--sp-card-text-color) !important;
}

.course-card .body .course-card-text-muted {
  color: var(--sp-card-text-muted-color) !important;
  font-weight: 500;
}

.course-card .upcoming-sessions {
  position: relative;
}

.course-card .upcoming-sessions .line {
  border-left: 1px solid var(--sp-card-text-color);
  height: 220px;
}

.course-card .upcoming-sessions h5 {
  font-size: 16px;
  margin-bottom: 30px;
}

.course-card .upcoming-sessions label {
  font-size: 14px;
  color: var(--sp-off-grey);
  font-weight: 500;
}

.course-card .upcoming-sessions p {
  font-size: 16px;
  font-weight: 600;
}

.course-card .upcoming-sessions .small-text {
  font-size: 10.5px;
  color: var(--sp-card-link-color);
  line-height: 18px;
  display: block;
  margin-top: 8px;
}

.course-card .footer {
  display: none;
  padding: 10px 25px;
}

.course-card .footer .upcoming-sessions {
  background: #FBFBFB;
  padding: 30px;
  margin-top: 90px;
}

.course-card .footer .upcoming-sessions:before {
  content: " ";
  display: inline-block;
  width: 5px;
  height: 50px;
  position: absolute;
  top: -70px;
  left: 50px;
  background: var(--sp-primary-color);
  border-radius: 15px;
}

.course-card .journey-progress {
  margin-top: 30px;
}

.course-card .journey-progress .primary-oulined-btn {
  font-weight: 700;
  padding: 8px 15px;
}

.course-card .journey-progress .primary-oulined-btn .fa {
  margin-left: 5px;
}

.course-card.active .primary-oulined-btn .fa:before {
  content: "\f077";
}

.course-card.active .footer {
  display: block;
}

.coach-card {
  background: var(--sp-coach-profile-background);
}

.btn {
  font-weight: 600 !important;
  padding: 10px 13px !important;
  border-radius: 4px !important;
  font-size: 0.80rem !important;
}

.btn img {
  height: 16px;
}

.large {
  font-size: 18px;
  padding: 14px 25px;
}

.full {
  width: 100%;
}

.btn-width {
  width: fit-content;
}

.primary-btn {
  background: var(--sp-primary-color) !important;
  color: var(--sp-primary-text-color) !important;
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  transition: color 0.3s ease;
}

.primary-btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  filter: brightness(1.1);
}


.submit-btn {
  background-color: var(--sp-action-btn-color) !important;
  color: var(--sp-action-btn-text-color) !important;
  border: 1px solid var(--sp-action-btn-color);
  width: fit-content;
  margin: 0 auto;
  padding: 10px 30px !important;
}

.submit-btn:hover {
  filter: brightness(1.15);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.side-card-btn:hover {
  background: #50627a !important;
  color: var(--sp-white-color) !important;
}

.actions-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 0px !important;
}

.primary-oulined-btn {
  background: var(--sp-white-color) !important;
  border: var(--sp-primary-color) 2px solid !important;
  color: var(--sp-primary-color) !important;
}

.primary-oulined-btn:hover,
.primary-oulined-btn:focus-visible {
  background: var(--sp-primary-color) !important;
  color: var(--sp-white-color) !important;
}

.no-style-btn {
  font-size: 18px !important;
  padding: 0;
  background: none;
  color: var(--sp-off-grey) !important;
}

.no-style-btn:hover {
  color: var(--sp-primary-color) !important;
}

.btn.disable {
  opacity: 0.4;
}

.modal .modal-dialog {
  max-width: 800px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  margin: 0;
  max-height: calc(100vh - 70px);
}

.modal .modal-dialog * {
  scrollbar-width: auto;
}

.modal .modal-dialog *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.modal .modal-dialog *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.03);
}

.modal .modal-dialog *::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.modal .modal-dialog .modal-content {
  overflow: inherit;
}

.modal .modal-dialog .modal-content .modal-header {
  position: relative;
  border: none;
}

.modal-title {
  background: var(--sp-modal-title-color);
  color: var(--sp-white-color) !important;
  text-align: center;
  width: 400px;
  max-width: 100%;
  transform: translateX(-50%);
  position: absolute;
  top: -20px;
  left: 50%;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

.modal .modal-dialog .modal-content .modal-header .btn-close {
  width: 38px;
  height: 38px;
  background-color: #D9D9D9;
  border-radius: 50%;
  font-size: 16px;
  padding: 0;
  position: absolute;
  right: -10px;
  top: -12px;
  opacity: 1;
}

.modal .modal-dialog .modal-content .modal-header .btn-close:hover,
.modal .modal-dialog .modal-content .modal-header .btn-close:focus-visible {
  background-color: var(--sp-primary-light-hover-color) !important;
  color: var(--sp-white-color) !important;
}

.modal .modal-dialog .modal-content .modal-body {
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 85%;
  margin: auto;
  height: 500px;
  overflow-y: overlay;
}

.modal .modal-dialog .modal-content .modal-body li {
  margin-bottom: 15px;
}

.modal .modal-dialog .modal-content .modal-body .list-group-item {
  margin: 0px;
}

.modal .modal-dialog .modal-content .modal-body li p {
  margin: 3px 0;
  color: var(--sp-text-black);
}

.modal .modal-dialog .modal-content .modal-body li p strong {
  color: var(--sp-off-grey);
  font-weight: 500;
}

.video-call {
  background: #FBFBFB;
  padding: 20px;
}

.video-call .video-wrapper {
  position: relative;
  margin-top: 55px;
}

.video-call .video-wrapper .large-image {
  height: 545px;
  overflow: hidden;
  border-radius: 5px;
}

.video-call .video-wrapper .large-image video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.video-call .video-wrapper .thumbnail {
  width: 170px;
  height: 170px;
  overflow: hidden;
  border-radius: 5px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.video-call .video-wrapper .thumbnail video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.video-call .video-wrapper .video-controls {
  position: absolute;
  bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.video-call .video-wrapper .video-controls .fa {
  background: #EA4335;
  color: var(--sp-white-color);
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
}

.video-call .video-wrapper .video-controls .fa:hover {
  cursor: pointer;
}

.video-call .video-wrapper .video-controls .microphone.active .fa:before {
  content: "\f131";
}

.notes {
  padding: 0;
  background: var(--sp-white-color);
  border-radius: 5px;
}

.notes .head {
  padding: 15px;
  border-bottom: var(--sp-off-grey) 1px solid;
}

.notes .head h4 {
  font-size: 20px;
  margin: 0;
}

.notes .content {
  height: calc(100% - 180px);
  padding: 15px;
  border: var(--sp-action-btn-color) 1px solid;
}

.notes .content *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.notes .content *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.03);
}

.notes .content *::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.notes .content .scoll {
  height: 100%;
  overflow-y: scroll;
}

.notes .content b {
  display: block;
  margin-bottom: 10px;
}

.notes .content ul {
  list-style: disc;
  margin-left: 20px;
}

.pagination {
  gap: 8px;
}

.pagination li a {
  background: rgba(46, 125, 232, 0.3);
  color: var(--sp-white-color);
  border: none;
  font-weight: 600;
  border-radius: 50% !important;
  width: 32px;
  line-height: 32px;
  padding: 0px;
  text-align: center;
}

.pagination li a:hover,
.pagination li a:focus-visible {
  background: var(--sp-primary-color);
  color: var(--sp-white-color);
}

.pagination li a .fa {
  font-size: 12px;
}

.faq .accordion {
  margin-top: 0px;
}

.faq .accordion .accordion-item {
  background: none;
  border: none;
  border-bottom: rgba(0, 0, 0, 0.2) 1px solid;
  padding: 0;
}

.faq .accordion .accordion-item:last-child {
  border-bottom: none;
}

.faq .accordion .accordion-item .accordion-button {
  font-weight: 600;
  padding: 20px 0;
  font-size: 16px;
}

.faq .accordion .accordion-item .accordion-button:not(.collapsed) {
  background: none;
  color: var(--sp-text-black);
}

.faq .accordion .accordion-item .accordion-button:not(.collapsed):after {
  -webkit-filter: brightness(0);
  filter: brightness(0);
}

.faq .accordion .accordion-item .accordion-button:after {
  color: var(--sp-text-black) !important;
}

.faq .accordion .accordion-item .accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.faq .accordion .accordion-item .accordion-button,
.faq .accordion .accordion-item .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.coaching-topics {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}

.owl-item {
  width: 330px !important;
  margin-right: 25px !important;
}

.coaching-topics .owl-stage {
  padding: 20px 0;
}

.coaching-topics .owl-nav {
  position: absolute;
  top: 43%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.coaching-topics .owl-nav .owl-prev,
.coaching-topics .owl-nav .owl-next {
  position: absolute;
  font-size: 50px !important;
}

.coaching-topics .owl-nav .owl-prev {
  left: -50px;
}

.coaching-topics .owl-nav .owl-next {
  right: -50px;
}

.coaching-topics .item {
  background: var(--sp-white-color);
  border-radius: 5px;
  -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transform: scale(1);
  transition: .3s ease;
  position: relative;
}

.coaching-topics .item:hover {
  transform: scale(1.05);
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

.coaching-topics .item .img-box {
  width: 100%;
  height: 230px;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}

.coaching-topics .item .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.coaching-topics .item .content {
  padding: 25px 30px;
  height: 130px;
  overflow: hidden;
}

.coaching-topics .item .content h4 {
  margin: 0;
  font-size: 18px;
  line-height: 1.4;
}

.open-topic-details {
  overflow: hidden;
}

.open-topic-details .overlay {
  display: block;
}

.open-topic-details .topic-details {
  right: 0px;
  -webkit-transition: right 0.8s ease-in-out 0s;
  transition: right 0.8s ease-in-out 0s;
}

.scrolldown .topic-details {
  top: 0;
}

.topic-details {
  width: 800px;
  position: fixed;
  top: 80px;
  right: -820px;
  -webkit-transition: right 0.8s ease-in-out 0s;
  transition: right 0.8s ease-in-out 0s;
  z-index: 9;
  background: var(--sp-drawer-color);
  padding: 50px 20px 50px 50px;
  height: 100vh;
  -webkit-box-shadow: -10px 0px 25px rgba(0, 0, 0, 0.2);
  box-shadow: -10px 0px 25px rgba(0, 0, 0, 0.2);
}

.topic-details * {
  scrollbar-width: auto;
}

.topic-details *::-webkit-scrollbar {
  width: 8px;
}

.topic-details *::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.03);
}

.topic-details *::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.topic-details .inner {
  height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.topic-details .inner .img-box {
  width: 300px;
  height: 250px;
  overflow: hidden;
  border-radius: 5px;
  margin: 20px 0;
}

.topic-details .inner .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.topic-details .inner ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-right: 50px;
  margin: 50px 0;
}

.topic-details .inner ul li {
  position: relative;
  padding-left: 30px;
}

.topic-details .inner ul li:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 3px;
  border-radius: 20px;
  background: var(--sp-primary-color);
}

.topic-details .inner ul li strong {
  font-size: 18px;
  font-weight: 700;
}

.topic-details .inner ul li p {
  font-weight: 600;
}

.topic-details .inner .call-to-action {
  -webkit-box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 20px;
  text-align: center;
}

.overlay {
  display: none;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.dropdown-toggle:after {
  content: "\f078" !important;
  font-family: "FontAwesome";
  border: none;
  font-size: 15px;
  position: relative;
  top: 2px;
}

.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.content-wrapper .content-area {
  padding: 50px 80px 20px;
}


.search {
  width: 100%;
  border: var(--sp-off-grey) 1px solid;
  border-radius: 5px;
  background: var(--sp-white-color);
  flex-wrap: wrap;
}

.content-wrapper .search input {
  width: 100%;
  border: none;
  padding: 10px 25px;
  border-radius: 6px;
}

.content-wrapper .search input[type=submit],
.content-wrapper .search button {
  background: none;
  border: none;
  margin-right: 20px;
  color: var(--sp-off-grey);
}

.card {
  padding: 30px;
  background: rgba(255, 255, 255, 0.3) !important;
  backdrop-filter: grayscale(0.5) opacity(0.8) !important;
  border-radius: 10px;
  box-shadow: 10px 8px 20px 10px rgba(0, 0, 0, 0.1) !important;
}

.course-heading {
  font-size: 18px;
  font-weight: 700;
  margin: 30px 0;
}

.info-card {
  background: var(--sp-white-color) !important;
  position: relative;
  border-radius: 5px;
}

.info-card h3 {
  background: var(--sp-modal-title-color) !important;
  color: var(--sp-white-color) !important;
  border-radius: 5px;
  font-size: 18px !important;
  padding: 14px 25px;
  width: 75%;
  position: absolute;
  left: 50%;
  top: -25px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.unleash ul p {
  margin: 0 0 8px 0;
}

.unleash ul p small {
  font-size: 13.5px !important;
}

.main-content {
  margin-left: 18% !important;
}

.content {
  margin: 0 !important;
}

.owl-carousel .owl-nav.disabled {
  display: none !important;
}

.btn-light {
  background: var(--sp-primary-light-color) !important;
  color: var(--sp-primary-light-text-color) !important;
  border: 2px solid var(--sp-primary-color) !important;
  cursor: pointer !important;
}

.btn-light:hover {
  filter: brightness(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.scroll-div {
  height: 40vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 15px;
}

.b_radius {
  border-radius: 0.5rem !important;
}

::-webkit-file-upload-button {
  background: none;
  color: var(--sp-text-black);
  border-radius: 0.5em !important;
  font-size: 8px;
}

.text {
  color: var(--sp-off-grey);
  font-style: italic;
  font-size: 12px;
}

.label {
  font-size: 14px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: var(--sp-off-grey) !important;
}

.label-black {
  font-size: 14px;
  font-family: 'Manrope';
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.01em;
}

.input-label {
  color: var(--sp-off-grey);
  font-weight: 500;
  font-size: 14px;
  font-family: "Manrope";
  line-height: 22px;
}

.quest-block {
  margin-bottom: 15px;
}

.quest-label {
  color: var(--sp-modal-text-color);
  font-weight: 500;
  font-size: 14px;
  font-family: "Manrope";
  line-height: 22px;
  margin-bottom: 3px;
}

.answer-text {
  color: var(--sp-off-grey);
  font-weight: 500;
  font-size: 14px !important;
  font-family: "Manrope";
  line-height: 22px;
}

.prep-quest-update-label {
  color: var(--sp-off-grey);
  font-size: 13px;
}

.error-message {
  color: var(--sp-danger-text-color);
}

.total-space-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: end;
  color: var(--sp-off-grey);
}

.choose-file {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: var(--sp-text-black);
  opacity: 0.6;
}

.btn-outline-danger {
  padding: 5px 13px !important;
}

.icon {
  width: 22px;
  cursor: pointer;
}

.primary-color {
  color: var(--sp-primary-color);
}

.secondary-color {
  color: var(--sp-secondary-color) !important;
}

.secondary-background {
  background: var(--sp-secondary-color);
}

.font-bold {
  font-weight: 500;
}

.font-light {
  font-weight: 300;
}


.whats_sparrks_para {
  padding: 50px;
  background: var(--sp-white-color) !important;
  box-shadow: 10px 8px 20px 10px rgba(0, 0, 0, 0.1);
}

.time-slot {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.account-pages {
  overflow: hidden;
  position: relative;
  height: 100vh;
}

.back-ground-image1,
.back-ground-image2,
.back-ground-image3 {
  position: absolute;
}

.back-ground-image1 {
  width: 60%;
  top: 0%;
  right: 0%;
}

.back-ground-image2 {
  width: 18%;
  top: 0%;
  left: 0%;
}

.back-ground-image3 {
  width: 9%;
  bottom: 5%;
  left: 0%;
}

.back-ground-image1 img,
.back-ground-image2 img,
.back-ground-image3 img {
  width: 100%;
  height: 100%;
}

.thanks-booking-block {
  .booking-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 32px;
    line-height: 50px;
    text-align: center;
    margin-top: 20px;
  }

  .subtitle {
    font-weight: 400 !important;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
  }
}

.disabled-button {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: none;
  border: 1px solid #999999;
}

.ngx-pagination .current {
  background: var(--sp-primary-color) !important;
  color: var(--sp-white-color) !important;
  ;
  font-size: 14px !important;
  font-family: 500 !important;
}

.ngx-pagination .current {
  cursor: pointer !important;
}

.btn-light:focus-visible,
.submit-btn:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--sp-primary-color) !important;
}

input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  outline: 2px solid var(--sp-primary-color) !important;
}

.list-title {
  margin-top: 10px;
  margin-bottom: 3px;
  color: var(--sp-off-grey);
  font-weight: 500 !important;
}

.text-overflow-div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
}

.search-select {
  background: var(--sp-white-color);
  border-radius: 0%;
  height: 43px;
  border: rgba(0, 0, 0, 0.4) 1px solid;
}

.share-doc-btn {
  width: max-content;
}

.consent-header {
  text-align: center;
  color: var(--sp-white-color) !important;
  background-color: var(--sp-primary-color);
  max-width: 100%;
  margin: auto;
  background-image: radial-gradient(at center center, var(--sp-primary-color) 0%, #005CD8 100%);
  margin-bottom: 30px;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.consent-header h4 {
  color: var(--sp-white-color)white !important;
}

.secondary-heading {
  font-size: 16px;
  color: var(--sp-primary-color) !important;
}

.consent-container {
  ol {
    font-size: 20px;
  }

  li,
  p {
    font-size: 14px;
  }
}

.main-heading h4 {
  color: var(--sp-primary-color) !important;
}

.main-heading {
  color: var(--sp-primary-color) !important;
}

.terms-condition-block {
  padding-right: 15px;
  padding-bottom: 20px;

  ol {
    font-size: 20px;
  }

  li,
  p {
    font-size: 14px;
  }
}

.position-relative {
  position: relative !important;
}

.new-documents-badge {
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  border-radius: 50% !important;
  background: #FF3B30;
  color: white;
  font-size: 12px !important;
}

.language-block {
  position: absolute;
  right: 15px;
  top: 0;
}

.card-title {
  font-size: 14px !important;
  font-weight: 500 !important;
}

#password-addon {
  border-radius: 1.5rem;
  background-color: transparent !important;
  position: absolute !important;
  right: 0;
  top: -2px;
  z-index: 5 !important;
  outline: none !important;
}

#password-addon:focus {
  box-shadow: none !important;
}

.login-input {
  padding: .5rem 1.25rem !important;
  border: 2px solid transparent;
  outline: none;
  border-radius: 1.5rem !important;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0 0 5pt 0.5pt #D3D3D3;
}

.card-container {
  position: absolute;
  top: 15%;
  right: 0%;
  left: 0%;
}

.auth-label {
  padding-left: 10px;
}

.new-file {
  font-weight: 600 !important;
}

.swal2-confirm {
  background: var(--sp-primary-color) !important;
  color: var(--sp-primary-text-color) !important;
  transition: color 0.3s ease;
}

.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-confirm:hover {
  filter: brightness(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-print {
  justify-content: center;
  align-items: center;
  display: flex;
  i {
    font-size: 25px !important;
    color:  var(--sp-off-grey) !important;
  }
}

.btn-print:focus-visible,
.btn-print:focus {
  outline: none;
  box-shadow: none !important;
}

.btn-outline-warning {
  border-color: transparent !important;
}

@media screen and (min-width: 991px) {
  .menuToggle {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .main-sidebar {
    left: -100%;
    -webkit-transition: left 0.8s ease-in-out 0s;
    transition: left 0.8s ease-in-out 0s;
  }

  .content-wrapper {
    padding-left: 0 !important;
  }

  .top-bar {
    padding: 20px 30px;
    width: 100%;
  }

  .upcoming-sessions .coach-full-css {
    margin-top: 20px;
  }

  .menu-open .main-sidebar {
    left: 0;
    -webkit-transition: left 0.8s ease-in-out 0s;
    transition: left 0.8s ease-in-out 0s;
  }

  .menu-open .main-sidebar .collapse {
    display: block;
  }

  .course-card .body .upcoming-sessions .tertiary-btn,
  .course-card .body .upcoming-sessions .actions-btn .btn,
  .actions-btn .course-card .body .upcoming-sessions .btn {
    margin-bottom: 3px;
  }

  .course-card .body .upcoming-sessions .actions-btn .btn {
    margin-bottom: 0;
  }

  .topic-details {
    width: 650px;
    right: -670px;
  }

  .topic-details .inner ul li strong {
    font-size: 16px;
  }

  .footer-btn-width {
    width: max-content;
  }

  .coaching-topics .item .content {
    padding: 20px 25px;
  }

  .coaching-topics .item .content h4 {
    font-size: 15px;
  }

  .responsive-btn {
    width: 50%;
  }

  .top-bar h2 {
    font-size: 24px;
  }

  .modal .modal-dialog .modal-content .modal-header .btn-close {
    right: -1px !important;
  }

  .owl-item {
    width: 200px !important;
    margin-right: 20px !important;
  }
}

@media screen and (min-width: 768px) {
  .menu-open .main-sidebar {
    width: 220px;
  }

  .search {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .top-bar #menuToggle {
    top: 5px;
  }

  .top-bar h2 {
    font-size: 18px;
    margin: 0;
  }

  .top-bar .profile-photo {
    width: 30px;
    height: 30px;
  }

  .top-bar .dropdown button {
    padding: 0;
  }

  .course-card .upcoming-sessions h5 {
    margin-top: 30px;

  }

  .course-card .footer .upcoming-sessions:before {
    left: 0px !important
  }

  .course-card .footer .footer-session-css {
    padding-left: 15px !important;
  }

  .upcoming-sessions .your-coach-css {
    margin-top: 0px !important;
  }

  .btn {
    padding: 9px 15px;
    font-size: 13px;
    cursor: pointer !important;
  }

  .btn.large {
    font-size: 16px;
    padding: 14px 30px;
  }

  .modal .modal-dialog {
    max-width: 85%;
  }

  .modal .modal-dialog .modal-content .modal-header .btn-close {
    right: -8px;
    top: -30px;
  }

  .modal .modal-dialog .modal-content .modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .video-call .video-wrapper {
    margin-top: 0;
  }

  .video-call .video-wrapper .large-image {
    height: 300px;
  }

  .video-call .video-wrapper .thumbnail {
    width: 85px;
    height: 85px;
  }

  .join-session-footer {
    text-align: center;
    gap: 20px;
  }

  .faq-page .info-card {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .faq-page .info-card h3 {
    text-align: center;
    font-size: 17px !important;
    padding: 14px 15px;
  }

  .topic-details {
    width: 350px;
    right: -375px;
    padding: 30px 30px 30px 30px;
  }

  .topic-details .inner {
    padding-right: 15px;
  }

  .topic-details .inner .img-box {
    width: 100%;
  }

  .topic-details .inner ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .topic-details .inner .img-box {
    height: 150px;
  }

  .modal .modal-dialog {
    width: 100%;
    max-width: 90%;
  }

  .course-card .upcoming-sessions .line {
    border-left: none;
    height: 230px;
    padding-left: 12px !important;
  }

  .join-btn-tab-view {
    padding: 10px 8px !important;
  }

  .menu-open .main-sidebar {
    width: 100%;
  }

  .content-wrapper .content-area {
    padding: 40px 10px 20px;
  }

  .whats_sparrks_para {
    padding: 50px 15px 20px 15px;
  }

  .faq .accordion .accordion-item .accordion-button:after {
    margin-left: 5px;
  }

  .coaching-topics .owl-nav .owl-prev {
    left: -35px;
  }

  .coaching-topics .owl-nav .owl-next {
    right: -35px;
  }

  .responsive-btn {
    width: 60%;
  }

  .time-slot {
    display: block;
  }

  .modal-content {
    border: none !important;
  }

  .modal .modal-dialog .modal-content .modal-header .btn-close {
    right: 13px !important;
    top: -8px !important;
  }

  .coaching-topics .item .img-box {
    width: 100%;
    height: 120px;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
  }

  .coaching-topics .item:hover {
    transform: none !important;
    z-index: auto !important;
  }

  .coaching-topics .item .content {
    padding: 10px 15px;
    height: 60px;
  }

  .course-heading {
    margin: 20px 0;
  }

  .coaching-topics .owl-stage {
    padding: 10px 0;
  }

  .course-card .header {
    line-height: 20px;
    font-size: 14px;
  }

  .input-label {
    line-height: 20px;
  }

  .owl-item {
    width: 120px !important;
    margin-right: 15px !important;
  }

  .coaching-topics .item .content h4 {
    line-height: 1;
    font-size: 10px;
  }

  .topic-details {
    padding: 70px 20px 20px 20px;
  }

  .quest-label {
    line-height: 18px;
  }

  .table-responsive {

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid var(--sp-light-gray);
      margin-bottom: 15px;
    }

    tr:nth-child(even) {
      background-color: #FBFBFB;
    }

    td {
      border: none;
      position: relative;
      padding-left: 35% !important;
    }

    td:before {
      position: absolute;
      top: 12px;
      left: 6px;
      width: 35%;
      padding-right: 10px;
      white-space: nowrap;
      font-weight: 700;
    }

    /*
	Label the data
	*/
    td:nth-of-type(1):before {
      content: "Title";
    }

    td:nth-of-type(2):before {
      content: "Date";
    }

    td:nth-of-type(3):before {
      content: "Size";
    }

    td:nth-of-type(4):before {
      content: "Uploaded by";
    }

    td:nth-of-type(5):before {
      content: "Download";
    }

    td:nth-of-type(6):before {
      content: "";
    }
  }

  .upload-file-block {
    width: 80%;
    margin: 0 auto;
  }

  .text-overflow-div {
    white-space: wrap;
    max-width: 100%;
  }

  .primary-btn {
    width: 100%;
  }

  .btn-width {
    width: 100%;
  }

  .small-font {
    font-size: 12px;
    line-height: 16px;
  }

  .modal-title {
    font-size: 15px !important;
  }

  .btn-close {
    width: 30px !important;
    height: 30px !important;
  }

  .share-doc-btn {
    width: 100% !important;
  }

  .new-documents-badge {
    top: -6px !important;
  }
}

@media only screen and (min-width: 601px) {
  .ratingTable {
    display: block;
  }

  .ratingSelect {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .ratingTable {
    display: none !important;
  }

  .ratingSelect {
    display: block !important;
  }

  .modal .modal-dialog .modal-content .modal-body {
    padding-top: 0px !important;
    padding-bottom: 20px !important;
    width: 100%;
    max-width: 95% !important;
  }

  .thanks-booking-block {
    .booking-title {
      font-size: 28px;
      line-height: 40px;
    }

    .subtitle {
      font-size: 12px;
      text-align: center;
      line-height: 20px;
    }
  }

  .card {
    padding: 10px;
  }

  .course-card .body {
    padding: 30px 25px 20px;
  }

  .course-card .journey-progress {
    margin-top: 15px;
  }

  .course-card .footer .upcoming-sessions {
    margin-top: 40px;
  }

  .course-card .footer .upcoming-sessions:before {
    height: 40px;
    top: -50px;
  }
}


@media screen and (max-width: 414px) {
  .top-bar {
    padding: 20px 20px;
    width: 100%;
  }

  .content-wrapper .content-area {
    padding-left: 10px !important;
  }

  .actions-btn {
    gap: 5px;
  }

  .course-card {
    width: 87vw;
  }

  .coaching-topics .owl-nav {
    position: absolute !important;
    top: 16% !important;
  }

  .scroll-div {
    height: 500px !important;
    overflow-y: scroll;
  }

  .btn-block {
    width: 100%;
    margin: 0 auto;
  }

  .search-block {
    border: var(--sp-light-gray) 1px solid;
  }

  .course-card .upcoming-sessions h5 {
    margin-top: 15px !important;
  }

  .consent-header {
    max-width: 100%;
  }
}

@media screen and (max-width: 378px) {
  .scroll-div {
    height: 240px !important;
    overflow-y: scroll;
  }
}
